<template>
	<div id="character" ref="character">
		<div class="nav">
			<top-vue></top-vue>
		</div>
		<div class="content">
			<h3 style="margin:0 auto"><span style="color: red;">说明：</span>每个维度仅可选择左边或右边其中一个选项，数量最多的一边为您的代码项</h3>
		</div>
		<div class="content">
			<li class="con fir">
				<h2>性格类型测试第1维度</h2>
				<div class="check">
					<el-form ref="form" :model="form">
						<el-form-item label="E">
							<el-checkbox-group v-model="form.type1">
								<el-checkbox v-for="item in list.list1" :label="item.id" :key="item.id" name="type"
									@change="handleChange(1, item)" :ref="'checkBox' + item.orders">
									{{ item.answerTitle }}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item label="I">
							<el-checkbox-group v-model="form.type2">
								<el-checkbox v-for="item in list.list2" :label="item.id" :key="item.id" name="type"
									@change="handleChange(2, item)" :ref="'checkBox' + item.orders">
									{{ item.answerTitle }}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					</el-form>
				</div>
			</li>
			<li class="con sec">
				<h2>性格类型测试第2维度</h2>
				<div class="check">
					<el-form ref="form" :model="form" label-width="80px">
						<el-form-item label="S">
							<el-checkbox-group v-model="form.type3">
								<el-checkbox v-for="item in list.list3" :label="item.id" :key="item.id" name="type"
									@change="handleChange(3, item)" :ref="'checkBox' + item.orders">
									{{ item.answerTitle }}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item label="N">
							<el-checkbox-group v-model="form.type4">
								<el-checkbox v-for="item in list.list4" :label="item.id" :key="item.id" name="type"
									@change="handleChange(4, item)" :ref="'checkBox' + item.orders">
									{{ item.answerTitle }}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					</el-form>
				</div>
			</li>
		</div>
		<div class="content">
			<li class="con fir">
				<h2>性格类型测试第3维度</h2>
				<div class="check">
					<el-form ref="form" :model="form" label-width="80px">
						<el-form-item label="T">
							<el-checkbox-group v-model="form.type5">
								<el-checkbox v-for="item in list.list5" :label="item.id" :key="item.id" name="type"
									@change="handleChange(5, item)" :ref="'checkBox' + item.orders">
									{{ item.answerTitle }}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item label="F">
							<el-checkbox-group v-model="form.type6">
								<el-checkbox v-for="item in list.list6" :label="item.id" :key="item.id" name="type"
									@change="handleChange(6, item)" :ref="'checkBox' + item.orders">
									{{ item.answerTitle }}
								</el-checkbox>

							</el-checkbox-group>
						</el-form-item>
					</el-form>
				</div>
			</li>
			<li class="con sec">
				<h2>性格类型测试第4维度</h2>
				<div class="check">
					<el-form ref="form" :model="form" label-width="80px">
						<el-form-item label="J">
							<el-checkbox-group v-model="form.type7">
								<el-checkbox v-for="item in list.list7" :label="item.id" :key="item.id" name="type"
									@change="handleChange(7, item)" :ref="'checkBox' + item.orders">
									{{ item.answerTitle }}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item label="P">
							<el-checkbox-group v-model="form.type8">
								<el-checkbox v-for="item in list.list8" :label="item.id" :key="item.id" name="type"
									@change="handleChange(8, item)" :ref="'checkBox' + item.orders">
									{{ item.answerTitle }}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
					</el-form>
				</div>
			</li>
		</div>

		<div style="width: 100px;height: 100px;margin: 0 auto;">
			<el-button @click="nextClick"
				style="border: none;padding: 15px 73px;color: #fff;font-size: 20px;font-weight: bold; border-radius: 25px;">
				下一步</el-button>
		</div>
	</div>
</template>

<script>
import {
	mapState,
} from "vuex"
import topVue from '@/components/top.vue';
import * as systemApi from "@/api/subjectSystemApi"

export default {
	components: {
		topVue
	},
	data() {
		return {
			form: {
				type1: [],
				type2: [],
				type3: [],
				type4: [],
				type5: [],
				type6: [],
				type7: [],
				type8: [],
			},
			list: {
				list1: [],
				list2: [],
				list3: [],
				list4: [],
				list5: [],
				list6: [],
				list7: [],
				list8: [],
			},

		}
	},
	created() {
		this.getStatus();
	},
	computed: {
		...mapState(["majorStatus", "subjectData"]),
	},
	methods: {
		handleChange(index, selectItem) {
			if(index % 2 == 0){
				// 选择2、4、6、8则取消选择1、3、5、7
				let changeItem = this.list['list'+(index-1)].find(u => u.orders==selectItem.orders);
				let findIndex = this.form['type'+(index-1)].findIndex(u => u==changeItem.id)
				//console.log(findIndex)
				if(findIndex !== -1){
					this.form['type'+(index-1)].splice(findIndex,1)
				}
			}else{
				// 选择1、3、5、7则取消选择2、4、6、8
				let changeItem = this.list['list'+(index+1)].find(u => u.orders==selectItem.orders);
				let findIndex = this.form['type'+(index+1)].findIndex(u => u==changeItem.id)
				//console.log(findIndex)
				if(findIndex !== -1){
					this.form['type'+(index+1)].splice(findIndex,1)
				}
			}
		},
		getStatus() {
			if (this.majorStatus == '') {
				let status = sessionStorage.getItem('majorStatus');
				this.$store.commit('setMajorStatus', status);
			}
			if (Object.keys(this.subjectData).length == 0) {
				let subjectData = JSON.parse(sessionStorage.getItem('subjectData'));
				console.log(subjectData);
				this.$store.commit('setSubjectData', subjectData);
			}
			if (this.majorStatus == '0') {
				this.$router.push('introduce');
			} else if (this.majorStatus == '1' || this.majorStatus == '2' || this.majorStatus == '3') {
				this.$router.push('mojor');
			} else if (this.majorStatus == '5') {
				this.$router.push('preliminary');
			} else if (this.majorStatus == '6') {
				this.$router.push('supplement');
			} else if (this.majorStatus == '7') {
				this.$router.push('final');
			}
			this.getList();
		},
		nextClick() {
			let mbtiItemIds = this.form.type1.join(',') + ',' + this.form.type2.join(',') + ',' + this.form.type3.join(
				',') + ',' + this
					.form.type4.join(',') + ',' + this.form.type5.join(',') + ',' + this.form.type6.join(',') + ',' + this
						.form.type7.join(
							',') + ',' + this.form.type8.join(',')
			// let listQuery = {
			// 	mbtiItemIds: this.form.type1.join(',') + ',' + this.form.type2.join(',') + ',' + this.form.type3
			// 		.join(
			// 			',') + ',' + this
			// 		.form.type4.join(',') + ',' + this.form.type5.join(',') + ',' + this.form.type6.join(',') +
			// 		',' + this
			// 		.form.type7.join(
			// 			',') + ',' + this.form.type8.join(',')
			// }
			//console.log(mbtiItemIds);
			console.log(mbtiItemIds);
			let MbtiAddEntity = {
				mbtiItemIds: mbtiItemIds,
				subjectTestId: this.subjectData.id
			}
			systemApi.mbtiNextAdd(MbtiAddEntity).then(res => {
				console.log(res);
				this.$nextTick(() => {
					this.$refs.character.scrollIntoView();
				});
				if (res.code == 200) {
					this.$store.commit('setMajorStatus', '5');
					this.$router.push('preliminary');
				}
			})
			// this.$router.push({
			// 	path: "/preliminary"
			// })
		},
		getList() {
			let listQuery = {
				limit: 999
			}
			systemApi.getMbtiItems(listQuery).then(res => {
				console.log(res);
				res.data.map(item => {
					if (item.syS_MBTIResultType == 'E') {
						this.list.list1.push(item)
					} else if (item.syS_MBTIResultType == 'I') {
						this.list.list2.push(item)
					} else if (item.syS_MBTIResultType == 'S') {
						this.list.list3.push(item)
					} else if (item.syS_MBTIResultType == 'N') {
						this.list.list4.push(item)
					} else if (item.syS_MBTIResultType == 'T') {
						this.list.list5.push(item)
					} else if (item.syS_MBTIResultType == 'F') {
						this.list.list6.push(item)
					} else if (item.syS_MBTIResultType == 'J') {
						this.list.list7.push(item)
					} else if (item.syS_MBTIResultType == 'P') {
						this.list.list8.push(item)
					}
				})
			})
		}
	}
}
</script>

<style>
.check {
	height: 400px;
	background: #fff;
	margin-bottom: 30px;
	border-radius: 0 0 10px 10px;
	padding: 10px;
}

li.con {
	width: calc(50% - 10px);
	margin: 0 5px;
}

.con h2 {
	background: #9eacfd;
	color: #fff;
	border-radius: 10px;
	margin: 0;
	padding: 20px 0;
}
</style>
<style rel="stylesheet/scss" lang="scss">
#character {
	.content {
		margin: 50px;
		display: flex;
		flex-wrap: wrap;
	}

	.el-form {
		display: flex;
	}

	.el-form-item {
		margin: 7px;
		flex: 1;
		box-shadow: 1px 1px 12px #ddd;
		padding-bottom: 10px;
	}

	.el-form-item__label {
		display: block;
		width: 100% !important;
		float: none;
		text-align: center;
		height: 50px;
		font-size: 22px;
		line-height: 50px;
		font-weight: bold;
		color: #000;
		border-bottom: 1px #e7e7e7 solid;
	}

	.el-form-item__content {
		font-size: 14px;
		margin: 0 !important;
		line-height: 20px;
	}

	.el-checkbox {
		width: 93%;
		text-align: left;
		color: #000000;
		margin-left: 18px;
		margin-top: 5px;
	}

	.el-checkbox__inner {
		border: 1px solid #000000;
	}

	.el-checkbox-group {
		margin-top: 15px;
		display: flex;
		flex-direction: column;
	}

	.el-checkbox__label {
		display: inline-grid;
		white-space: pre-line;
		padding-left: 10px;
		padding-right: 20px;
		font-size: 14px;
	}

	.el-button {
		background: #9eacfd;
	}

	.el-button:focus,
	.el-button:hover {
		background: #9eacfd !important;
	}
}
</style>
